import React from "react";
import Header from "../partials/Header";
import Footer from "../partials/Footer";

const About = () => {
    return (
        <div class=" bg-white">
            <div>
                <Header />
                <div class="container m-auto px-6 text-gray-100 md:px-12 xl:px-6 py-16 2xl:px-16">
                    <div class="space-y-6 md:space-y-0 md:flex md:gap-6 lg:items-center lg:gap-12">
                        <div class="md:5/12 lg:w-5/12">
                            <img src="https://th.bing.com/th/id/R.0752c5528c55c47a18bbbf47a65c6f92?rik=iPJoEYAO1izQ5A&riu=http%3a%2f%2fclipart-library.com%2fimages_k%2fjunk-food-transparent%2fjunk-food-transparent-5.png&ehk=qaBh7UlFBPxDt2Gp3%2fQ2WWUNcNoxe73crRYQI9fC76M%3d&risl=&pid=ImgRaw&r=0" alt="image" loading="lazy" width="" height="" />
                        </div>
                        <div class="md:7/12 lg:w-6/12">
                            <h2 class="text-2xl text-gray-950 font-bold md:text-4xl">At Cafe Piala cooking is carried out by passionate Chefs</h2>
                            <p class="mt-6 text-gray-800">Indulge in an exquisite culinary journey at Cafe Pialla, where our passion for flavor meets a warm and inviting atmosphere. Whether you're seeking a delightful breakfast, a leisurely lunch, or an enchanting dinner, our restaurant is the perfect destination for a memorable dining experience.</p>
                            <p class="mt-4 text-gray-800">The best companion for your cravings.</p>
                        </div>
                    </div>
                </div>
            </div>
           
            <Footer />
        </div>
    );
}

export default About;