import React, { useState, useEffect } from "react";
import Pagination from "./Pagination";

const AdminOrders = () => {




    const updateOrders = async (itemId, newStatus) => {
             

        try {
            const formData = new FormData();
            formData.append('id', itemId);
            formData.append('status', newStatus);

            const response = await fetch('https://www.cafepiala.shop/php/update-orders.php', {
                method: 'POST',
                body: formData,
            });

            const json = await response.json();

            if (json.success === true) {
                // Added successfully

                orders();


            } else {
                // Failed to add

           

            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }

     
      };
    

      
    function handleRadioClick(itemId, newStatus) {
       

          updateOrders(itemId, newStatus);

    }

    const [dataOrders, setDataOrders] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);

    const [delivered, setDelivered] = useState(0);
    const [confirmed, setConfirmed] = useState(0);
    const [cancelled, setCancelled] = useState(0);
    const [pending, setPendling] = useState(0);
    const [total, setTotal] = useState(0);

    const orders = async () => {
        try {
        
          const response = await fetch(
            `https://www.cafepiala.shop/php/orders.php?page=${encodeURIComponent(currentPage)}`
          );
          const json = await response.json();
    
          console.log(json[0].totalRows);

        if (Object.keys(json).length === 0) {
            setTotalPages(0);
        }
        else {

            setCancelled(json[0].cancelledCount);
            setConfirmed(json[0].confirmedCount);
            setPendling(json[0].pendingCount);
            setDelivered(json[0].deliveredCount);
            setTotal(json[0].totalRows);

            if (json[0].totalrows % 10 === 0) {
                setTotalPages((json[0].totalRows) / 10);
            }
            else {
                setTotalPages(Math.floor(json[0].totalRows / 10) + 1);
             
            }

            if (Array.isArray(json)) {
                setDataOrders(json);
                console.log(json);
              } else {
                console.error('Received data is not an array:', json);
              }
        }
    
          
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
        
        }
      };


      const fetchInterval = 5 * 60 * 1000; // 5 minutes in milliseconds
      setInterval(orders, fetchInterval);



      useEffect(() => {
        orders();
      }, []);


  


      const [totalPages, setTotalPages] = useState(1);


     

      useEffect(() => {
        orders();
      }, [currentPage]);

      const handlePageChange = (page) => {

 
  
          if (page >= 1 && page <= totalPages) {
              setCurrentPage(page);
          }
  
          // Perform any other actions or fetch data based on the new page
      };

      const getFirstUppercaseLetter = (str) => {
        return str.charAt(0).toUpperCase();
      };

    return (

        <div class="overflow-x-auto md:rounded-lg border border-gray-200 shadow-md my-5 md:mx-10">


<div  class='flex bg-white shadow-md justify-start md:justify-center rounded-lg overflow-x-scroll mx-auto py-4 px-2 space-x-8'>
        

<div class='flex group bg-purple-300 shadow-lg light-shadow rounded-lg mx-1 cursor-pointer justify-center relative w-16 content-center'>
          <span class="flex h-3 w-3 absolute -top-1 -right-1">
            <span class="animate-ping absolute group-hover:opacity-75 opacity-0 inline-flex h-full w-full rounded-full bg-purple-400 "></span>
            <span class="relative inline-flex rounded-full h-3 w-3 bg-purple-500"></span>
          </span>
            <div class='flex items-center px-4 py-4'>
                <div class='text-center'>
                   <p class='text-purple-900 text-sm'> pending </p>
                   <p class='text-purple-900  mt-3 font-bold'>{pending}</p>
                </div>
            </div>
        </div>

        <div class='flex group hover:bg-purple-100 hover:shadow-lg hover-light-shadow rounded-lg mx-1 transition-all	duration-300	 cursor-pointer justify-center w-16'>
            <div class='flex items-center px-4 py-4'>
                <div class='text-center'>
                   <p class='text-gray-900 group-hover:text-purple-900 text-sm transition-all	duration-300'> Confirmed </p>
                   <p class='text-gray-900 group-hover:text-purple-900 mt-3 group-hover:font-bold transition-all	duration-300'>{confirmed}</p>
                </div>
            </div>
        </div>
      
      <div class='flex group hover:bg-purple-100 hover:shadow-lg hover-light-shadow rounded-lg mx-1 transition-all	duration-300	 cursor-pointer justify-center w-16'>
            <div class='flex items-center px-4 py-4'>
                <div class='text-center'>
                   <p class='text-gray-900 group-hover:text-purple-900 text-sm transition-all	duration-300'> Delivered </p>
                   <p class='text-gray-900 group-hover:text-purple-900 mt-3 group-hover:font-bold transition-all	duration-300'>{delivered}</p>
                </div>
            </div>
        </div>
      
      <div class='flex group hover:bg-purple-100 hover:shadow-lg hover-light-shadow rounded-lg mx-1 transition-all	duration-300	 cursor-pointer justify-center w-16'>
            <div class='flex items-center px-4 py-4'>
                <div class='text-center'>
                   <p class='text-gray-900 group-hover:text-purple-900 text-sm transition-all	duration-300'> Cancelled </p>
                   <p class='text-gray-900 group-hover:text-purple-900 mt-3 group-hover:font-bold transition-all	duration-300'>{cancelled}</p>
                </div>
            </div>
        </div>
      
      
      
      <div class='flex group hover:bg-purple-100 hover:shadow-lg hover-light-shadow rounded-lg mx-1 transition-all	duration-300 content-center	 cursor-pointer justify-center w-16'>
            <div class='flex items-center px-4 py-4'>
                <div class='text-center'>
                   <p class='text-gray-900 group-hover:text-purple-900 text-sm transition-all	duration-300'> Orders </p>
                   <p class='text-gray-900 group-hover:text-purple-900 mt-3 group-hover:font-bold transition-all	duration-300'>{total}</p>
                </div>
            </div>
        </div>
      
      
        
      
    </div>




            <table class="w-full border-collapse bg-white text-left text-sm text-gray-500">
                <thead class="bg-gray-50">
                    <tr>
                        <th scope="col" class="px-6 py-4 font-medium text-gray-900">Order id</th>
                        <th scope="col" class="px-6 py-4 font-medium text-gray-900">Contact</th>
                        <th scope="col" class="px-6 py-4 font-medium text-gray-900">State</th>
                        <th scope="col" class="px-6 py-4 font-medium text-gray-900">Order</th>
                        {/* <th scope="col" class="px-6 py-4 font-medium text-gray-900">Team</th> */}
                        <th scope="col" class="px-6 py-4 font-medium text-gray-900">Cost</th>
                        <th scope="col" class="px-6 py-4 font-medium text-gray-900"></th>
                    </tr>
                </thead>
                <tbody class="divide-y divide-gray-100 border-t border-gray-100">
                {Array.isArray(dataOrders) && dataOrders.map((item, index) => (
                    <tr class="hover:bg-gray-50">
                        <th className="px-6">#{item.id}</th>
                        <td class="flex gap-3 px-6 py-4 font-normal text-gray-900">
                            <div class="relative h-10 w-10">
                            <div className="rounded-full bg-gray-950 shadow w-8 h-8 flex items-center justify-center relative ml-4 font-bold text-white">
                  
                    {getFirstUppercaseLetter(item.name)}
                  </div>
                                <span class="absolute -right-1 bottom-1 h-2 w-2 rounded-full bg-green-400 ring ring-white"></span>
                            </div>
                            <div class="text-sm">
                                <div class="font-medium text-gray-700">{item.name}</div>
                                <div class="text-gray-400">{item.phone}</div>
                                <div class="text-gray-400">{item.address}</div>

                            </div>
                        </td>
                        <td className="px-6 py-4">
  {item.status === "delivered" && (
    <span className="inline-flex items-center gap-1 rounded-full bg-green-50 px-2 py-1 text-xs font-semibold text-green-600">
      <span className="h-1.5 w-1.5 rounded-full bg-green-600"></span>
      Delivered
    </span>
  )}
  {item.status === "cancelled" && (
    <span className="inline-flex items-center gap-1 rounded-full bg-red-50 px-2 py-1 text-xs font-semibold text-red-600">
      <span className="h-1.5 w-1.5 rounded-full bg-red-600"></span>
      Cancelled
    </span>
  )}
  {item.status === "pending" && (
    <span className="inline-flex items-center gap-1 rounded-full bg-gray-50 px-2 py-1 text-xs font-semibold text-gray-600">
      <span className="h-1.5 w-1.5 rounded-full bg-gray-600"></span>
      Pending
    </span>
  )}
    {item.status === "confirmed" && (
    <span className="inline-flex items-center gap-1 rounded-full bg-sky-50 px-2 py-1 text-xs font-semibold text-sky-600">
      <span className="h-1.5 w-1.5 rounded-full bg-sky-600"></span>
      Confirmed
    </span>
  )}
</td>

                       
                        <td className="px-6 py-4" dangerouslySetInnerHTML={{ __html: item.meal.replace(/\n/g, '<br />') }}></td>
                        {/* <td className="px-6 py-4">{item.meal}</td> */}

                        <td class="px-6 py-4 flex flex-row font-bold">
                            {item.total}<span className="font-normal"> .\Rs</span>
                        </td>
                        <td class="px-6 py-4">

                            <div className="flex justify-end gap-4">
                                <div className="main flex border rounded-full overflow-hidden m-4 select-none">
                                    <div className="title py-3 my-auto px-5 bg-blue-500 text-white text-sm font-semibold mr-3">Status</div>

                                    <label className="flex radio p-2 cursor-pointer">
      <input
        type="radio"
        name={`radio-${item.id}`}
        onClick={() => handleRadioClick(item.id, 'pending')}
        checked={item.status === 'pending'}
      />
      <div className="title px-2">Pending</div>
    </label>

    <label className="flex radio p-2 cursor-pointer">
      <input
        type="radio"
        name={`radio-${item.id}`}
        onClick={() => handleRadioClick(item.id, 'confirmed')}
        checked={item.status === 'confirmed'}
      />
      <div className="title px-2">Confirmed</div>
    </label>

    <label className="flex radio p-2 cursor-pointer">
      <input
        type="radio"
        name={`radio-${item.id}`}
        onClick={() => handleRadioClick(item.id, 'delivered')}
        checked={item.status === 'delivered'}
      />
      <div className="title px-2">Delivered</div>
    </label>

    <label className="flex radio p-2 cursor-pointer">
      <input
        type="radio"
        name={`radio-${item.id}`}
        onClick={() => handleRadioClick(item.id, 'cancelled')}
        checked={item.status === 'cancelled'}
      />
      <div className="title px-2">Cancelled</div>
    </label>

                                </div>
                            </div>
                        </td>


                    </tr>
                          ))}
                    
                </tbody>
            </table>

            <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />

        </div>

    );
}

export default AdminOrders;