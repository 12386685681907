import React, { useState, useEffect } from "react";
import { FaCartArrowDown } from "react-icons/fa";
import SidebarMenu from "./SidebarDark";
import { Link } from 'react-router-dom'
import { FaSignOutAlt } from 'react-icons/fa';
import cafepiala from '../images/cafepiala.png'

const Header = () => {

  const websitePrefix = 'www.cafepiala.shop';

    const [publicLogin, setPublicLogin] = useState('');
    const [name, setName] = useState('');
  

    const getFirstUppercaseLetter = (str) => {
        return str.charAt(0).toUpperCase();
      };

  
    useEffect(() => {
      setPublicLogin(localStorage.getItem(`${websitePrefix}-publiclogin`));
      setName(localStorage.getItem(`${websitePrefix}-publicname`));
    }, []);




    
    
      const [isMenuOpen, setMenuOpen] = useState(false);
    

    
      const handleLogout = () => {
        setMenuOpen(!isMenuOpen);
      };
    
      const closeOverlay = () => {
        setMenuOpen(!isMenuOpen);
      };
    
      const handlePublicLogout = () => {
        localStorage.setItem(`${websitePrefix}-publiclogin`, null);
        localStorage.setItem(`${websitePrefix}-publicid`, null);
        localStorage.setItem(`${websitePrefix}-publicname`, null);
        localStorage.setItem(`${websitePrefix}-publicemail`, null);
        localStorage.setItem(`${websitePrefix}-publicpassword`, null);
        localStorage.setItem(`${websitePrefix}-publicphone`, null);
        localStorage.setItem(`${websitePrefix}-publicaddress`, null);
        closeOverlay();
        setPublicLogin(null);
      };

      
    

    return(
        <header className="font-poppins">
        <nav class="bg-transparent bg-opacity-0 px-4 lg:px-6 py-5">
            <div class="flex flex-wrap justify-between items-center mx-auto max-w-screen-xl">
            <SidebarMenu />
                <Link to="/" class="flex items-center">
                    <img src={cafepiala} class="mr-3 h-6 sm:h-9" alt="Flowbite Logo" />
                    <span class="self-center text-xl font-semibold whitespace-nowrap text-gray-950">Cafe Piala</span>
                </Link>
                <div class="flex items-center lg:order-2 border-l-2 pl-4">
                <Link to="/cart">
                    <FaCartArrowDown className="text-gray-950 w-6 h-6"/>
                    </Link>
                <div className="flex space-x-4">
            {publicLogin !== '1' ? (
              <div className="flex space-x-2">
                {/* <button onClick={handleSignUp} className="ml-4 bg-transparent duration-300 text-sm text-sky-700 font-semibold py-2 px-4 hover:border-transparent rounded">
                  Sign Up
                </button> */}
                <Link to="/login" className="duration-300 bg-neutral-950 text-xs text-white font-bold px-4 py-2 rounded-full ml-4">
                  Login
                </Link>
              </div>

            ) : (

              <div className="container mx-auto flex items-center justify-between px-4">
                <div className="flex flex-row items-center justify-between">
                  <div className="rounded-full bg-gray-950 text-white shadow w-8 h-8 flex items-center justify-center relative ml-4 font-bold">
                    {/* Add your avatar image or icon here */}
                    {/* <img
                      className="rounded-full object-cover object-center"
                      src={`https://gigadevden.com${image}`}
                      alt="public-profile-icon"
                      style={{ width: '100%', height: '100%' }}
                    /> */}
                    {getFirstUppercaseLetter(name)}
                  </div>

                  <button
                    className="flex items-center text-neutral-950 focus:outline-none ml-4 p-2 rounded"
                    onClick={handleLogout}
                  >
                    <div className="flex flex-row ">
                      {/* <div className="dots w-1 h-1 bg-black dark:bg-black rounded-full"></div>
                      <div className="dots w-1 h-1 bg-black dark:bg-black rounded-full ml-[2px]"></div>
                      <div className="dots w-1 h-1 bg-black dark:bg-black rounded-full ml-[2px]"></div> */}
                      <FaSignOutAlt className="text-gray-950" />
                    </div>
                  </button>

                  {/* {isMenuOpen && (
                    <ul className="absolute right-0 top-full mt-2 w-48 bg-white rounded shadow-lg py-2">
                      <li className="py-1 px-4 hover:text-gray-700 text-gray-500 cursor-pointer">Profile</li>
                      <li className="py-1 px-4 hover:text-gray-700 text-gray-500 cursor-pointer">Settings</li>
                      <li className="py-1 px-4 hover:text-gray-700 text-gray-500 cursor-pointer">Logout</li>
                    </ul>
                  )} */}

                  {isMenuOpen && (
                    <div
                      className="overlay"
                      style={{
                        position: 'fixed',
                        top: 80,
                        right: 32,
                        display: 'flex',
                        alignItems: 'flex-start',
                        justifyContent: 'flex-end',
                        zIndex: 9998,
                        opacity: isMenuOpen ? 1 : 0,
                        transition: 'opacity 0.5s ease-in-out',
                      }}
                    >
                      <div
                        className="overlay-content"
                        style={{
                          backgroundColor: 'black',
                          padding: '20px',
                          borderRadius: '16px',
                          maxWidth: '400px'
                        }}
                      >
                        <div className="flex pb-3 items-center">
                          <div className="-ml-1 text-gray-200">
                            <FaSignOutAlt />
                          </div>
                          <p className="text-lg text-gray-100 font-semibold pl-2">Confirm logout</p>
                          <button
                            className="close-overlay"
                            onClick={closeOverlay}
                            style={{
                              marginLeft: 'auto',
                              cursor: 'pointer',
                              color: 'white'
                            }}
                          >
                            <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-x" width="24" height="24" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" fill="none" strokeLinecap="round" strokeLinejoin="round">
                              <path stroke="none" d="M0 0h24v24H0z" />
                              <line x1="18" y1="6" x2="6" y2="18" />
                              <line x1="6" y1="6" x2="18" y2="18" />
                            </svg>
                          </button>
                        </div>
                        <p className="text-sm text-gray-200 pb-3 font-normal mb-2">Do you really want to logout?</p>
                        <button
                          onClick={() => handlePublicLogout()}
                          className="delete-account-btn focus:outline-none bg-red-400 transition duration-150 ease-in-out hover:bg-red-500 rounded text-white px-3 py-2 text-xs"
                        >
                          Logout
                        </button>
                      </div>
                    </div>
                  )}


                </div>
              </div>

            )}


          </div>
                    {/* <Link to="/login" class="text-white hover:bg-gray-50 focus:ring-4 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 dark:hover:bg-gray-700 focus:outline-none focus:ring-gray-800">Log in</Link> */}
                    
                    {/* <a href="#" class="text-white bg-gray-900 hover:bg-gray-950 focus:ring-4 focus:ring-gray-300 font-medium rounded-lg text-sm px-4 lg:px-5 py-2 lg:py-2.5 mr-2 focus:outline-none ">Get started</a> */}
                    {/* <button data-collapse-toggle="mobile-menu-2" type="button" class="inline-flex items-center p-2 ml-1 text-sm text-gray-500 rounded-lg lg:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600" aria-controls="mobile-menu-2" aria-expanded="false">
                        <span class="sr-only">Open main menu</span>
                        <svg class="w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z" clip-rule="evenodd"></path></svg>
                        <svg class="hidden w-6 h-6" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z" clip-rule="evenodd"></path></svg>
                    </button> */}
                   
                </div>
                <div class="hidden justify-between items-center w-full lg:flex lg:w-auto lg:order-1" id="mobile-menu-2">
                    <ul class="flex flex-col mt-4 font-normal lg:flex-row lg:space-x-8 lg:mt-0 text-sm">
                        {/* <li>
                            <a href="#" class="block py-2 pr-4 pl-3 text-white rounded bg-blue-700 lg:bg-transparent lg:text-blue-700 lg:p-0 dark:text-white" aria-current="page">Home</a>
                        </li> */}
                        <li>
                            <Link to="/" class="block py-2 pr-4 pl-3 text-gray-900 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-gray-600 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Home</Link>
                        </li>
                        <li>
                            <Link to="/menu" class="block py-2 pr-4 pl-3 text-gray-900 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-gray-600 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Menu</Link>
                        </li>
                        <li>
                            <Link to="/about" class="block py-2 pr-4 pl-3 text-gray-900 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-gray-600 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">About us</Link>
                        </li>
                        {/* <li>
                            <a href="#" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Team</a>
                        </li>
                        <li>
                            <a href="#" class="block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 lg:hover:bg-transparent lg:border-0 lg:hover:text-blue-700 lg:p-0 dark:text-gray-400 lg:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white lg:dark:hover:bg-transparent dark:border-gray-700">Contact</a>
                        </li> */}
                    </ul>
                </div>
            </div>
        </nav>
    </header>
    );
}

export default Header;