import React from 'react';
import { HashRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Menu from './pages/Menu';
import Admin from './pages/Admin';
import Cart from './pages/Cart';
import AdminLogin from './pages/AdminLogin';
import About from './pages/About';
import Login from './pages/Login';
import SignUp from './pages/SignUp';
import { AuthProvider, useAuth } from './AuthContext';
import DevControl from './pages/DevControl';

function App() {
  return (
    <AuthProvider> {/* Wrap your entire app with AuthProvider */}
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/menu" element={<Menu />} />
          <Route path="/admin" element={<AdminOrLogin />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/adminlogin" element={<AdminLogin />} />
          <Route path="/about" element={<About />} />
          <Route path="/login" element={<Login />} />
          <Route path="/signup" element={<SignUp />} />


          {/* <Route path="/" element={<DevControl />} />
          <Route path="/menu" element={<DevControl />} />
          <Route path="/admin" element={<DevControl />} />
          <Route path="/cart" element={<DevControl />} />
          <Route path="/adminlogin" element={<DevControl />} />
          <Route path="/about" element={<DevControl />} />
          <Route path="/login" element={<DevControl />} />
          <Route path="/signup" element={<DevControl />} />


          <Route path="/paytogo" element={<DevControl />} /> */}
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;

function AdminOrLogin() {
  // Use the AuthContext here to access admin status
  const { adminStatus } = useAuth();

  // If adminStatus is '1', render the Admin component; otherwise, redirect to AdminLogin
  return adminStatus === '1' ? <Admin /> : <Navigate to="/adminlogin" />;
}
